import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const InstallButtonStyled = styled(Button)({
  backgroundColor: "#20D561",
  padding: "16px 40px 16px 40px",
  borderRadius: "32px",
  fontFamily: "Chirp",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "19.09px",
  color: "#212121",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#20D561",
    opacity: "0.8",
  },
});

const InstallButton: React.FC = () => {
  const handleInstallButton = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    window.open("https://apps.apple.com/vn/app/trinity/id6657977300", "_blank");
  };

  return (
    <InstallButtonStyled onClick={handleInstallButton}>
      Install now
    </InstallButtonStyled>
  );
};

export default InstallButton;
